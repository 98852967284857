@import "../../../assets/scss/utils/variables";

.container {
  .empty-content {
    width: 100%;
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    align-items: center;
    justify-content: center;
  
    span {
      font-size: 24px;
      line-height: 28px;
      color: $color-primary;
      font-weight: 500;
      font-family: SpaceGroteskSemiBold, sans-serif;
    }
  
    &.dark {
      span {
        color: $color-white;
      }
    }
  }

  &.dark {
    .empty-content {
      span {
        color: $color-white;
      }
    }
  }
}
