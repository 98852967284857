@import 'assets/scss/utils/variables';
@import 'assets/scss/reset/reset';

.subscribe {
  button {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 8px 16px;
    column-gap: 8px;
    background-color: $color-green;
    margin: 0 32px 0 20px;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: $color-green-hover;
    }

    span {
      color: $color-primary;
      font-family: SpaceGroteskMedium, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .subscribe {
    width: 100%;

    button {
      width: 100%;
      justify-content: center;
      margin: 0;  
    }
  }
}
