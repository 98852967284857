@import "../../../assets/scss/utils/variables";

.mode-switcher {
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: max-content;

  .switcher {
    width: 40px;
    height: 24px;
    border-radius: 16px;
    background-color: $color-midnight-blue;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $color-light-steel-gray;
      position: absolute;
      left: 2px;
      top: 2px;
      z-index: 10;
      transition: 400ms all;
    }

    &.active {
      background-color: $color-light-green;

      &::after {
        left: 18px;
        background-color: $color-green;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .mode-switcher {
    .switcher {
      background-color: $color-light-green;
  
      &::after {
        content: '';
        background-color: $color-green;
      }
  
      &.active {
        background-color: $color-midnight-blue;
  
        &::after {
          background-color: $color-light-steel-gray;
        }
      }
    }
  }
}
