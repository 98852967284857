@import './../../assets/scss/utils/variables';
@import './../../assets/scss/reset/reset';

.container {
  .empty-icon {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $color-green;
    position: relative;
  }

  main.history-inner {
    width: 920px;
    margin: auto;
  
    .content {
      width: 100%;
      position: relative;
      z-index: 999;
      margin-top: -120px;
      margin-bottom: 24px;
      background-color: $color-white;
      border: 1px solid $color-light-silver;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      row-gap: 24px;
      padding: 40px;
  
      &-title {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 12px;
        padding-bottom: 16px;
  
        &-img {
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          position: relative;
          background-color: $color-green;
          z-index: 99;

          &.incident {
            background-color: $color-red;
          }
        }
  
        h1 {
          font-size: 28px;
          line-height: 36px;
          font-weight: 700;
          color: $color-primary;
          font-family: SpaceGroteskSemiBold, sans-serif;
        }
      }
  
      &-item {
        width: 100%;
        border-radius: 12px;
        background-color: $color-bg-grey;
        padding: 32px;
  
        .item-title {
          font-size: 20px;
          line-height: 26px;
          font-weight: 600;
          margin-bottom: 12px;
          color: $color-primary;
          text-transform: capitalize;
          font-family: SpaceGroteskMedium, sans-serif;
        }
  
        .item-desc, .item-name {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          margin-bottom: 8px;
          color: $color-primary;
          font-family: SpaceGroteskRegular, sans-serif;
        }
  
        .item-name {
          font-weight: 600;
        }      
  
        .item-date {
          font-size: 15px;
          line-height: 20px;
          font-weight: 400;
          color: $color-muted;
          font-family: SpaceGroteskRegular, sans-serif;
        }
      }
    }
  
    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .btn {
        background-color: $color-white;
        border: 1px solid $color-light-silver;
        border-radius: 8px;
        padding: 17px 32px;
        color: $color-muted;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        cursor: pointer;
        font-family: SpaceGroteskMedium, sans-serif;
      }
  
      &:hover {
        .btn {
          background-color: $color-icy-gray;
        }
      }
  
      &:focus {
        .btn {
          background-color: $color-navy-slate;
          color: $color-white;
        }
      }
    }
  }
  
  main.histories {
    width: 920px;
    margin: auto;
  
    .head {
      width: 100%;
      height: 284px;
      position: relative;
      z-index: 999;
      margin-top: -120px;
      background-color: $color-white;
      border: 1px solid $color-light-silver;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 48px 136.5px;
      margin-bottom: 32px;
  
      &-header {
        h1 {
          font-size: 28px;
          line-height: 36px;
          font-weight: 700;
          color: $color-muted;
          text-align: center;
          font-family: SpaceGroteskSemiBold, sans-serif;
        }
      }
  
      &-description {
        display: flex;
        justify-content: center;
        align-items: center;
  
        .switch-button {
          background: none;
          border: 1px solid $color-light-silver;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          cursor: pointer;
  
          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
  
          &:nth-of-type(2) {
            svg {
              transform: rotate(180deg);
            }
          }
        }
  
        p {
          font-size: 15px;
          line-height: 20px;
          font-weight: 400;
          color: $color-gentle-blue;
          text-align: center;
          margin: 0 12px;
          width: 252px;
          font-family: SpaceGroteskRegular, sans-serif;
        }
      }
    }
  
    .histories-container {
      min-height: max-content;
      width: 100%;
      border: 1px solid $color-light-silver;
      border-radius: 12px;
  
      &-content {
        margin: 30px;
  
        &:not(:first-child) {
          margin-top: 72px;
        }
  
        h2 {
          font-weight: bold;
          font-size: 24px;
          line-height: 32px;
          color: $color-primary;
          font-family: SpaceGroteskSemiBold, sans-serif;
        }
  
        .incidents {
          .incident {
            margin-top: 40px;
            display: flex;
            position: relative;
  
            h5 {
              font-weight: 300;
              font-size: 14px;
              line-height: 18px;
              text-transform: capitalize;
              color: $color-primary;
              padding: 2px 5px;
              margin: 8px 0 18px;
              border-radius: 3px;
              display: inline-block;
              font-family: SpaceGroteskRegular, sans-serif;
  
              .open {
                color: $color-light-red;
              }
  
              .resolved {
                color: $color-light-green;
              }
            }
      
            &::before {
              content: "";
              width: 2px;
              position: absolute;
              height: 100%;
              top: 40px;
              left: 19px;
            }
  
            .incident-logo {
              z-index: 1;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-repeat: no-repeat;
              background-position: center;
            }
  
            &.open {
              .incident-logo {
                background: $color-light-red;
              }
              
              &::before {
                background-color: $color-light-red;
              }
  
              h5 {
                background-color: $color-light-red;
              }
            }
  
            &.resolved {
              .incident-logo {
                background: $color-light-green;
              }
  
              &::before {
                background-color: $color-light-green;
              }
  
              h5 {
                background-color: $color-light-green;
              }
            }
  
            .incident-description {
              margin-left: 16px;
              flex: 1;
  
              a {
                display: block;
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
                font-family: SpaceGroteskMedium, sans-serif;
                color: $color-primary;
              }
  
              h3 {
                margin-top: 11px;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                color: $color-muted;
                font-family: SpaceGroteskMedium, sans-serif;
              }
  
              span {
                position: relative;
                top: 5px;
                font-size: 15px;
                font-weight: normal;
                line-height: 20px;
                color: $color-muted;
                font-family: SpaceGroteskRegular, sans-serif;
              }
            }
  
            &:last-child {
              &::before {
                height: calc(100% - 40px);
              }
            }
          }
        }
      }
  
      button {
        width: 244px;
        height: 48px;
        margin: 72px auto 20px;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-light-silver;
        border-radius: 8px;
        color: $color-muted;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        cursor: pointer;
        column-gap: 12px;
        font-family: SpaceGroteskMedium, sans-serif;
  
        &:hover {
          background-color: $color-icy-gray;
        }
    
        &:focus {
          background-color: $color-navy-slate;
          color: $color-white;
        }
      }
  
      .empty {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 80px;
        width: 100%;
        height: 100%;
      }
    }
  }

  &.dark {
    main.history-inner {
      .content {
        background-color: $color-primary;
        border: 1.5px solid $color-slate-gray;
  
        &-title {  
          h1 {
            color: $color-white;
          }
        }
  
        &-item {
          background-color: $color-secondary;
  
          .item-title {
            color: $color-white;
          }
  
          .item-desc, .item-name {
            color: $color-white;
          }      
  
          .item-date {
            color: $color-light-steel-gray;
          }
        }
      }
  
      .buttons {
        .btn {
          border: 1.5px solid $color-slate-gray;
          background-color: $color-primary;
          color: $color-white;
        }
  
        &:hover {
          .btn {
            background-color: $color-midnight-blue;
          }
        }
  
        &:focus {
          .btn {
            background-color: $color-midnight-blue;
            color: $color-green;
          }
        }
      }
    }
    
    main.histories {    
      .head {
        background-color: $color-primary;
        border: 1.5px solid $color-slate-gray;
  
        &-header {
          h1 {
            color: $color-white;
          }
        }
  
        &-description {
          .switch-button {
            border: 1.5px solid $color-light-steel-gray;
          }
  
          p {
            color: $color-light-steel-gray;
          }
        }
      }
  
      .histories-container {
        border: 1.5px solid $color-slate-gray;
  
        &-content {
          h2 {
            color: $color-white;
          }
  
          .incidents {
            .incident {
              h5 {
                color: $color-primary;
  
                .open {
                  color: $color-light-red;
                }
  
                .resolved {
                  color: $color-light-green;
                }
              }
  
              &.open {
                .incident-logo {
                  background: $color-light-red;
                }
                
                &::before {
                  background-color: $color-light-red;
                }
  
                h5 {
                  background-color: $color-light-red;
                }
              }
  
              &.resolved {
                .incident-logo {
                  background: $color-light-green;
                }
  
                &::before {
                  background-color: $color-light-steel-gray;
                }
  
                h5 {
                  background-color: $color-light-green;
                }
              }
  
              .incident-description {
                h3 {
                  color: $color-light-steel-gray;
                }

                a {
                  color: $color-white;
                }
  
                span {
                  color: $color-light-steel-gray;
                }
              }
            }
          }
        }
  
        button {
          border: 1.5px solid $color-slate-gray;
          background-color: $color-primary;
          color: $color-white;
        
          &:hover {
            background-color: $color-midnight-blue;
          }
    
          &:focus {
            background-color: $color-midnight-blue;
            color: $color-green;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md-large) {
  .container {
    main.history-inner {
      width: 100%;
    }
  
    main.histories {
      width: 100%;
      padding: 0 16px;
  
      .head {
        padding: 36px 80px;
      }
  
      .history-container {
        .content {
          margin: 28px;
  
          h2 {
            font-size: 24px;
          }
  
          .incidents {
            .incident {
              .incident-description {
                h2 {
                  font-size: 18px;
                }
              }
            }
          }
  
          button {
            margin: 65px auto 18px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .container {
    main.history-inner {
      padding: 0 16px;
  
      .content {
        padding: 32px;
        margin-bottom: 18px;
        margin-top: -50px;
  
        &-title {
          h1 {
            font-size: 20px;
            line-height: 22px;
          }
        }
  
        &-item {
          padding: 24px;
  
          .item-title {
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 10px;
          }
  
          .item-desc {
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 8px;
          }
  
          .item-date {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
  
      .buttons {
        button {
          padding: 12px 24px;
          font-size: 14px;
          line-height: 17px;
          gap: 12px;
        }
      }
    }
  
    main.histories {
      width: 100%;
      padding: 0 16px;
  
      .head {
        padding: 36px 80px;
        margin-top: -50px;
      }
  
      .histories-container {
        &-content {
          margin: 24px;
  
          h2 {
            font-size: 20px;
          }
  
          .incidents {
            &::before {
              left: 17px;
            }
  
            .incident {
              margin-top: 30px;
  
              .incident-logo {
                width: 34px;
                height: 34px;
              }
      
              &::before {
                top: 34px;
                left: 16px;
              }
  
              .incident-description {
                a {
                  font-size: 17px;
                }
              }
            }
          }
  
          button {
            margin: 60px auto 17px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .container {
    main.history-inner {
      .content {
        height: unset;
        margin-top: -50px;
        gap: 14px;
        padding: 14px;
  
        &-title {
          h1 {
            font-size: 18px;
            line-height: 20px;
          }
          
          &-img {
            width: 36px;
            height: 36px;
  
            &.incident {
              background-color: $color-red;
            }
          }
        }
  
        &-item {
          padding: 20px;
  
          .item-title {
            font-size: 16px;
            line-height: 17px;
          }
  
          .item-desc {
            font-size: 13px;
            line-height: 15px;
          }
  
          .item-date {
            font-size: 13px;
            line-height: 15px;
          }
        }
      }
  
      .buttons {
        button {
          padding: 10px 18px;
          font-size: 13px;
          line-height: 15px;
          gap: 8px;
  
          img {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  
    main.histories {
      .head {
        height: unset;
        margin-top: -50px;
        gap: 14px;
        padding: 14px;
        margin-bottom: 18px;
  
        &-header {
          h1 {
            font-size: 22px;
            line-height: 26px;
            font-weight: 600;
          }
        }
  
        &-description {
          p {
            font-size: 14px;
            line-height: 17px;
            width: 235px;
          }
        }
      }
  
      .histories-container {
        &-content {
          margin: 20px;
  
          h2 {
            font-size: 18px;
          }
  
          .incidents {
            &::before {
              left: 15px;
            }
  
            .incident {
              margin-top: 25px;
  
              .incident-logo {
                width: 30px;
                height: 30px;
              }
      
              &::before {
                top: 30px;
                left: 14px;
              }
  
              .incident-description {
                a {
                  font-size: 16px;
                }
  
                h3,
                span {
                  font-size: 14px;
                }
              }
            }
          }
  
          button {
            margin: 55px auto 16px;
          }
        }
      }
    }
  }
}