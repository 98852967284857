@import "./../../assets/scss/utils/variables";
@import "./../../assets/scss/reset/reset";

.container {
  main.home {
    width: 920px;
    margin: auto;

    .head {
      width: 744px;
      margin: auto;
      position: relative;
      z-index: 999;
      margin-top: -120px;
      background-color: $color-white;
      border: 1px solid $color-light-silver;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 48px 136.5px;
      margin-bottom: 40px;
      row-gap: 20px;

      &-header {
        h1 {
          font-size: 28px;
          line-height: 36px;
          font-weight: 700;
          color: $color-muted;
          text-align: center;
          font-family: SpaceGroteskSemiBold, sans-serif;
        }
      }

      &-description {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: $color-slate-gray;
        text-align: center;
        font-family: SpaceGroteskRegular, sans-serif;
      }

      &-img {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        z-index: 99;

        .pulse {
          position: absolute;
          z-index: -1;
        }
      }
    }

    .history {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      margin-bottom: 80px;

      &-item {
        width: 100%;
        border: 1px solid $color-light-silver;
        border-radius: 8px;
        padding: 0 24px;
        cursor: pointer;

        .progress-section {
          padding: 16px;
          border-radius: 6px;
          background-color: #f8fafc;
          margin-bottom: 24px;

          .component-title {
            display: flex;
            align-items: center;
            column-gap: 10px;
            margin-bottom: 16px;

            span {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                width: 14px;
                height: 14px;
              }
            }

            .tick {
              background-color: $color-green;
            }

            .cross {
              background-color: $color-red;
            }

            .component-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex: 1;

              &-text {
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
                color: $color-primary;
                font-family: SpaceGroteskMedium, sans-serif;
              }

              .component-log-info {
                position: relative;
                display: flex;
                align-items: center;

                .component-log-info-desc {
                  position: absolute;
                  opacity: 0;
                  visibility: hidden;
                  transition: 400ms all;
                  font-size: 12px;
                  line-height: 14px;
                  font-weight: 400;
                  color: $color-white;
                  border-radius: 8px;
                  max-width: 180px;
                  width: max-content;
                  padding: 12px;
                  height: auto;
                  background-color: $color-muted;
                  transform: translate(0, -50%);
                  right: 27px;
                  top: 50%;
                  z-index: 999;
                  font-family: SpaceGroteskRegular, sans-serif;

                  &::after {
                    content: "";
                    width: 10px;
                    height: 10px;
                    background-color: $color-muted;
                    position: absolute;
                    right: -4px;
                    top: calc(50% - 5px);
                    transform: rotate(45deg);
                  }
                }

                &:hover {
                  .component-log-info-desc {
                    opacity: 1;
                    visibility: visible;
                  }
                }
              }
            }
          }

          .progress-bars {
            height: 44px;
            margin-bottom: 12px;
            display: flex;
            justify-content: space-between;

            .progress-bar {
              width: 10px;
              border-radius: 2px;
              background-color: $color-green;
              position: relative;

              &::before {
                content: "";
                width: 10px;
                height: 44px;
                position: absolute;
                background-color: inherit;
                border-radius: 2px;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
              }

              &::after {
                content: "";
                background-color: $color-white;
                z-index: 999;
                width: 10px;
                height: 10px;
                position: absolute;
                left: calc(50% - 6px);
                transform: rotate(45deg);
                bottom: -20px;
                display: none;
                border-top: 1px solid $color-light-silver;
                border-left: 1px solid $color-light-silver;
              }

              &.non-operational {
                background-color: $color-red;
              }

              &.null {
                background-color: $color-light-silver;
              }

              .no-data {
                display: none;
                width: 144px;
                height: 72px;
                border-radius: 8px;
                padding: 16px 12px;
                background-color: $color-white;
                box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0;
                position: absolute;
                bottom: -86px;
                left: -67px;
                z-index: 999;
                border: 1px solid $color-light-silver;
                flex-direction: column;
                align-items: center;

                h5 {
                  font-size: 13px;
                  line-height: 18px;
                  font-weight: 400;
                  color: $color-muted;
                  text-align: center;
                  font-family: SpaceGroteskRegular, sans-serif;
                }

                span {
                  font-size: 13px;
                  line-height: 18px;
                  font-weight: 600;
                  color: $color-muted;
                  font-family: SpaceGroteskRegular, sans-serif;
                }
              }

              .no-incident {
                display: none;
                width: 174px;
                border-radius: 8px;
                padding: 16px 12px 12px;
                background-color: $color-white;
                box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0;
                position: absolute;
                top: 58px;
                left: -115px;
                z-index: 999;
                border: 1px solid $color-light-silver;
                flex-direction: column;
                height: max-content;
                align-items: center;

                .status {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  column-gap: 8px;
                  padding-bottom: 6px;
                  border-bottom: 1px solid $color-light-silver;

                  span {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 700;
                    color: $color-primary;
                    font-family: SpaceGroteskSemiBold, sans-serif;
                  }

                  &-icon {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }

                  .tick {
                    background-color: $color-green;
                  }
                }

                h5 {
                  font-size: 13px;
                  line-height: 18px;
                  font-weight: 400;
                  color: $color-muted;
                  text-align: center;
                  margin: 8px 0;
                  font-family: SpaceGroteskRegular, sans-serif;
                }

                .no-incident-text {
                  color: $color-green;
                  font-size: 12px;
                  line-height: 16px;
                  font-weight: 500;
                  font-family: SpaceGroteskMedium, sans-serif;
                }
              }

              .detect-incident {
                display: none;
                width: 198px;
                border-radius: 8px;
                padding: 16px 12px 12px;
                background-color: $color-white;
                box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0;
                position: absolute;
                top: 58px;
                left: -115px;
                z-index: 999;
                height: max-content;
                border: 1px solid $color-light-silver;
                flex-direction: column;

                .status {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  column-gap: 8px;
                  padding-bottom: 6px;
                  border-bottom: 1px solid $color-light-silver;

                  span {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 700;
                    color: $color-primary;
                    font-family: SpaceGroteskSemiBold, sans-serif;
                  }

                  &-icon {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }

                  .cross {
                    background-color: $color-red;
                  }
                }

                h5 {
                  font-size: 13px;
                  line-height: 18px;
                  font-weight: 400;
                  color: $color-muted;
                  text-align: center;
                  margin: 8px 0;
                  font-family: SpaceGroteskRegular, sans-serif;
                }

                .incident-desc,
                .duration {
                  font-size: 12px;
                  line-height: 16px;
                  font-weight: 400;
                  color: $color-red;
                  font-family: SpaceGroteskRegular, sans-serif;
                }

                .duration {
                  color: $color-primary;
                  font-family: SpaceGroteskMedium, sans-serif;
                  margin-top: 6px;
                }
              }

              &:hover {
                &::before {
                  width: 12px;
                  height: 52px;
                }

                .no-incident,
                .detect-incident,
                .no-data {
                  display: flex;
                }

                &::after {
                  display: block;
                }
              }
            }
          }

          .time-interval {
            display: flex;
            justify-content: space-between;

            h4 {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              color: #393e46;
              font-family: SpaceGroteskMedium, sans-serif;
            }

            .uptime {
              display: flex;
              justify-content: flex-end;

              h4 {
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                color: #393e46;
                font-family: SpaceGroteskMedium, sans-serif;
              }
            }
          }

          .open-incident {
            margin-top: 24px;
            display: flex;
            flex-direction: column;

            &-head {
              display: flex;
              column-gap: 10px;
              align-items: center;

              .icon {
                width: 24px;
                height: 24px;
                background-color: $color-red;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  transform: rotate(180deg);
                  width: 16px;
                  height: 16px;
                }
              }

              .text {
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
                font-family: SpaceGroteskMedium, sans-serif;
                color: $color-primary;
              }
            }

            &-desc {
              margin: 12px 0 24px;
              padding-left: 34px;
              color: $color-red;
              font-size: 16px;
              line-height: 22px;
              font-weight: 400;
              font-family: SpaceGroteskRegular, sans-serif;
            }

            &-started {
              padding-left: 34px;
              color: $color-muted;
              font-size: 14px;
              line-height: 18px;
              font-weight: 400;
              font-family: SpaceGroteskRegular, sans-serif;
            }

            &.component {
              margin-top: 20px;

              .component-open-incident-head {
                display: flex;
                column-gap: 10px;
                align-items: center;

                .component-icon {
                  width: 20px;
                  height: 20px;
                  background-color: $color-red;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .component-text {
                  font-size: 18px;
                  line-height: 24px;
                  font-weight: 500;
                  font-family: SpaceGroteskMedium, sans-serif;
                  color: $color-primary;
                }
              }

              .open-incident-desc {
                margin: 10px 0 14px;
                padding-left: 30px;
                font-size: 15px;
              }

              .component-open-incident-started {
                padding-left: 30px;
                color: $color-muted;
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                font-family: SpaceGroteskRegular, sans-serif;
              }
            }
          }
        }

        .head-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px 0;

          .log-status {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: $color-primary;
            font-family: SpaceGroteskMedium, sans-serif;

            .arrow {
              svg {
                transform: rotate(180deg);
                transition: 400ms all;
              }

              &.open {
                svg {
                  transform: rotate(0deg);
                  transition: 400ms all;
                }
              }
            }
          }

          .log-title {
            display: flex;
            align-items: center;
            column-gap: 10px;

            .component-tick {
              background-color: $color-green;
              min-width: 24px;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .component-cross {
              background-color: $color-red;
            }

            p {
              font-size: 18px;
              line-height: 24px;
              font-weight: 700;
              color: $color-primary;
              font-family: SpaceGroteskSemiBold, sans-serif;

              &.disabled-component-text {
                color: $color-white;
                font-family: SpaceGroteskRegular, sans-serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
              }
            }

            .log-info {
              position: relative;
              display: flex;
              align-items: center;

              &-desc {
                position: absolute;
                opacity: 0;
                visibility: hidden;
                transition: 400ms all;
                font-size: 12px;
                line-height: 14px;
                font-weight: 400;
                color: $color-white;
                border-radius: 8px;
                max-width: 180px;
                width: max-content;
                padding: 12px;
                height: auto;
                background-color: $color-muted;
                transform: translate(0, -50%);
                left: 27px;
                top: 50%;
                z-index: 999;
                font-family: SpaceGroteskRegular, sans-serif;

                &::after {
                  content: "";
                  width: 10px;
                  height: 10px;
                  background-color: $color-muted;
                  position: absolute;
                  left: -4px;
                  top: calc(50% - 5px);
                  transform: rotate(45deg);
                }
              }

              &:hover {
                .log-info-desc {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }

        .group-container {
          margin: 0;

          .head-container {
            padding: 0;

            .log-title {
              p {
                font-size: 16px;
                line-height: 22px;
                font-weight: 500;
              }

              .info {
                .desc {
                  font-size: 12px;
                  font-weight: 400;
                  color: $color-white;
                }
              }
            }
          }

          .progress-section {
            padding-top: 20px;
          }
        }

        .incident-info {
          opacity: 0;
          visibility: hidden;
          max-height: 0;
          transition: 400ms all;

          .log-text {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: $color-red;
            padding-top: 24px;
          }
        }

        .active {
          opacity: 1;
          visibility: visible;
          max-height: 1000px;
        }
      }

      .disabled-component {
        position: relative;
        display: flex;
        align-items: center;

        svg#disabled-icon {
          height: 24px;
          width: 24px;
        }

        .disabled-component-text {
          position: absolute;
          opacity: 0;
          visibility: hidden;
          transition: 400ms all;
          font-size: 12px;
          line-height: 14px;
          font-weight: 400;
          color: $color-white;
          border-radius: 8px;
          max-width: 180px;
          width: max-content;
          padding: 12px;
          height: auto;
          background-color: $color-muted;
          transform: translate(0, -50%);
          left: 27px;
          top: 50%;
          z-index: 999;
          font-family: SpaceGroteskRegular, sans-serif;

          &::after {
            content: "";
            width: 10px;
            height: 10px;
            background-color: $color-muted;
            position: absolute;
            left: -2px;
            top: calc(50% - 5px);
            transform: rotate(45deg);
          }
        }

        &:hover {
          .disabled-component-text {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    .go-to {
      border: 1px solid $color-light-silver;
      border-radius: 8px;
      margin-bottom: 120px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px;
        gap: 10px;
        cursor: pointer;

        h3 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: $color-primary;
          font-family: SpaceGroteskRegular, sans-serif;
        }
      }

      &:hover {
        background-color: $color-icy-gray;
      }

      &:focus {
        background-color: $color-navy-slate;

        a {
          h3 {
            color: $color-white;
          }
        }
      }
    }
  }

  &.dark {
    main.home {
      .head {
        background-color: $color-primary;
        border: 1.5px solid $color-slate-gray;

        &-header {
          h1 {
            color: $color-white;
          }
        }

        &-description {
          color: #cbd5e1;
        }
      }

      .history {
        &-item {
          border: 1.5px solid $color-slate-gray;

          .head-container {
            .log-status {
              color: $color-white;
            }

            .log-title {
              p {
                color: $color-white;
              }
            }
          }

          .progress-section {
            background-color: $color-secondary;

            .component-title {
              .component-header-text {
                color: $color-white;
              }
            }

            .progress-bars {
              .progress-bar {
                background-color: $color-green;

                &::before {
                  background-color: inherit;
                }

                &::after {
                  background-color: $color-midnight-blue;
                  border-top: 1px solid $color-slate-gray;
                  border-left: 1px solid $color-slate-gray;
                }

                &.non-operational {
                  background-color: $color-red;
                }

                &.null {
                  background-color: $color-light-silver;
                }

                .no-data {
                  background-color: $color-midnight-blue;
                  border: 1px solid $color-slate-gray;

                  h5 {
                    color: $color-gentle-blue;
                  }

                  span {
                    color: $color-white;
                  }
                }

                .no-incident {
                  background-color: $color-midnight-blue;
                  box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0;
                  border: 1px solid $color-slate-gray;

                  .status {
                    border-bottom: 1px solid $color-slate-gray;

                    span {
                      color: $color-white;
                    }
                  }

                  h5 {
                    color: $color-gentle-blue;
                  }
                }

                .detect-incident {
                  background-color: $color-midnight-blue;
                  box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0;
                  border: 1px solid $color-slate-gray;

                  .status {
                    border-bottom: 1px solid $color-slate-gray;

                    span {
                      color: $color-white;
                    }
                  }

                  h5 {
                    color: $color-gentle-blue;
                  }

                  .duration {
                    color: $color-white;
                  }
                }
              }
            }

            .time-interval {
              h4 {
                color: $color-light-steel-gray;
              }

              .uptime {
                h4 {
                  color: $color-light-steel-gray;
                }
              }
            }

            .open-incident {
              &-head {
                .icon {
                  background-color: $color-red;
                }

                .text {
                  color: $color-white;
                }
              }

              &-started {
                color: $color-light-steel-gray;
              }

              &.component {
                .component-open-incident-head {
                  .component-text {
                    color: $color-white;
                  }
                }

                .component-open-incident-started {
                  color: $color-light-steel-gray;
                }
              }
            }
          }

          .group-container {
            .head-container {
              .log-title {
                .log-info {
                  &-desc {
                    color: $color-white;
                  }
                }
              }
            }
          }

          .incident-info {
            .log-text {
              color: $color-red;
            }
          }
        }
      }

      .go-to {
        border: 1.5px solid $color-slate-gray;
        background-color: $color-primary;

        a {
          h3 {
            color: $color-white;
          }
        }

        &:hover {
          background-color: $color-midnight-blue;
        }

        &:focus {
          background-color: $color-midnight-blue;

          a {
            h3 {
              color: $color-green;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md-large) {
  .container {
    main.home {
      width: 100%;
      padding: 0 16px;

      .head {
        width: 100%;
      }

      .progress-section {
        .progress-bars {
          flex-wrap: wrap;
          gap: 0.9%;
          row-gap: 8px;
          height: unset;

          .progress-bar {
            width: 2.37%;
            height: 44px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .container {
    main.home {
      .history {
        &-item {
          .progress-section {
            .progress-bars {
              position: relative;

              .progress-bar {
                &:nth-last-child(-n + 6) {
                  .detect-incident,
                  .no-data,
                  .no-incident {
                    left: unset;
                    right: -24px;
                  }
                }

                &:nth-child(-n + 6) {
                  .detect-incident,
                  .no-data,
                  .no-incident {
                    left: -24px;
                  }
                }
              }
            }

            .time-interval {
              display: flex;
              justify-content: space-between;

              h4 {
                font-size: 15px;
                line-height: 20px;
                font-weight: 400;
                color: $color-primary;
              }

              .uptime {
                display: flex;
                justify-content: flex-end;

                h4 {
                  font-size: 14px;
                  line-height: 18px;
                  font-weight: 400;
                  color: $color-primary;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .container {
    main.home {
      .head {
        margin: auto;
        height: unset;
        margin-top: -50px;
        align-items: center;
        padding: 32px 0;
        margin-bottom: 24px;

        &-header {
          margin: 20px 0;

          h1 {
            font-size: 22px;
            line-height: 24px;
          }
        }

        &-description {
          font-size: 14px;
          line-height: 18px;
        }

        &-img {
          width: 48px;
          height: 48px;

          &::before {
            top: -6px;
            left: -6px;
            width: 60px;
            height: 60px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .container {
    main.home {
      .head {
        margin: auto;
        height: unset;
        margin-top: -50px;
        align-items: center;
        padding: 32px 0;
        margin-bottom: 24px;

        &-header {
          margin: 20px 0;

          h1 {
            font-size: 22px;
            line-height: 24px;
          }
        }

        &-description {
          font-size: 14px;
          line-height: 18px;
        }

        &-img {
          width: 48px;
          height: 48px;

          &::before {
            top: -6px;
            left: -6px;
            width: 60px;
            height: 60px;
          }
        }
      }

      .history {
        gap: 12px;
        margin-bottom: 28px;

        &-item {
          padding: 8px;

          .head-container {
            padding: 4px 0;
            column-gap: 10px;
            overflow: hidden;

            .log-title {
              max-width: 220px;

              p {
                font-size: 16px;
                line-height: 18px;
                font-weight: 600;
                max-width: max-content;
              }
            }
          }

          .progress-section {
            padding: 8px 6px !important;
            margin-top: 14px;

            .progress-bars {
              row-gap: 6px;

              .progress-bar {
                border-radius: 1px;
              }
            }

            .time-interval {
              h4 {
                font-size: 14px;
                line-height: 16px;
              }
            }
          }

          .group-container {
            margin-top: 14px;

            .progress-section {
              padding-top: unset;
            }
          }

          .incident-info {
            .log-text {
              font-size: 14px;
              line-height: 18px;
              font-weight: 300;
              margin-top: 14px;
            }
          }
        }
      }

      .go-to {
        margin-bottom: 40px;

        a {
          padding: 12px;
          gap: 6px;

          h3 {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
          }

          img {
            width: 14px;
            height: 10px;
          }
        }
      }
    }
  }
}

@media screen and (760px <= width <= 860px) {
  .container {
    main.home {
      .history {
        &-item {
          .progress-section {
            .progress-bars {
              .progress-bar {
                width: 7.5px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (width <= 480px) {
  .container {
    main.home {
      .history {
        &-item {
          .progress-section {
            .progress-bars {
              .progress-bar {
                width: 8px;
              }
            }
          }
        }
      }

      .go-to {
        margin-bottom: 40px;

        a {
          padding: 12px;
          gap: 6px;

          h3 {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
          }

          img {
            width: 14px;
            height: 10px;
          }
        }
      }
    }
  }
}

@media screen and (width <= 390px) {
  .container {
    main.home {
      .history {
        &-item {
          .progress-section {
            .progress-bars {
              .progress-bar {
                width: 7.5px;
              }
            }
          }
        }
      }
    }
  }
}
