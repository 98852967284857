@import './../../assets/scss/utils/variables';
@import './../../assets/scss/reset/reset';

.container {
  main.subscription {
    width: 920px;
    margin: auto;
  
    .content {
      width: 100%;
      min-height: 284px;
      position: relative;
      z-index: 999;
      margin-top: -120px;
      background-color: $color-white;
      border: 1px solid $color-light-silver;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 48px 136.5px;

      .content-loader {
        padding: 0;
      }
  
      .verify {
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 32px;
  
        .icon {
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          position: relative;
          background-color: $color-green;
          z-index: 99;

          &.error {
            background-color: $color-red;
          }
        }

        h3 {
          font-size: 22px;
          line-height: 36px;
          font-weight: 500;
          color: $color-primary;
          text-align: center;
          font-family: SpaceGroteskRegular, sans-serif;
        }
      }

      .unsubscribe {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        row-gap: 24px;
  
        .unsubscribed-message {
          display: flex;
          align-items: center;
          flex-direction: column;
          row-gap: 32px;
    
          .icon {
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;
            background-color: $color-green;
            z-index: 99;
          }

          h4 {
            font-size: 22px;
            line-height: 36px;
            font-weight: 500;
            color: $color-primary;
            text-align: center;
            font-family: SpaceGroteskRegular, sans-serif;
          }
        }

        h3 {
          font-size: 20px;
          line-height: 24px;
          font-weight: 700;
          color: $color-primary;
          text-align: center;
          font-family: SpaceGroteskSemiBold, sans-serif;
        }

        ul {
          list-style-type: disc;
          
          p, li {
            font-size: 16px;
            line-height: 18px;
            font-weight: 500;
            color: $color-primary;
            text-align: center;
            font-family: SpaceGroteskRegular, sans-serif;
          }

          li {
            font-size: 14px;
            text-align: left;
            padding: 28px 0 28px 22px;
            font-weight: 500;
            position: relative;
            
            &::before {
              content: '';
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: $color-muted;
              position: absolute;
              transform: translate(0,-50%);
              top: 50%;
              left: 10px;
            }
          }
        }

        .buttons {
          display: flex;
          justify-content: center;
          column-gap: 16px;

          .button {
            padding: 8px 0;
            width: 132px;
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            color: $color-primary;
            font-family: SpaceGroteskRegular, sans-serif;
            border: 1px solid $color-light-silver;
            background-color: $color-white;
            border-radius: 6px;
            text-align: center;
            cursor: pointer;

            a {
              color: $color-primary;
            }

            &:disabled {
              cursor: not-allowed;
              opacity: 0.7;
            }

            &:hover {
              opacity: 0.9;
            }

            &:last-child {
              background-color: $color-red;
              color: $color-white;
              border: none;
            }
          }
        }
      }
    }    
  }

  &.dark {
    main.subscription {
      .content {
        background-color: $color-primary;
        border: 1.5px solid $color-slate-gray;
  
        .verify {
          h3 {
            color: $color-white;
          }
        }

        .unsubscribe {
          .unsubscribed-message {
            h4 {
              color: $color-white;
            }
          }

          h3 {
            color: $color-white;
          }
  
          ul {
            p, li {
              color: $color-white;

              &::before {
                background-color: $color-white;
              }
            }
          }

          .buttons {
            .button {
              color: $color-white;
              background-color: $color-primary;

              a {
                color: $color-white;
              }
  
              &:last-child {
                background-color: $color-red;
                color: $color-white;
                border: none;
              }
            }
          }
        }
      } 
    }
  }
}

@media screen and (max-width: $breakpoint-md-large) {
  .container {
    main.subscription {
      width: 95%;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .container {
    main.subscription {
      .content {
        margin-top: -40px;
        padding: 32px;
      }    
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .container {
    main.subscription {
      .content {
        padding: 16px;
        margin-top: 60px;
  
        .verify {
          .icon {
            width: 48px;
            height: 48px;
          }
  
          h3 {
            font-size: 18px;
            line-height: 22px;
          }
        }
  
        .unsubscribe {
          h3 {
            font-size: 18px;
            line-height: 22px;
            font-weight: 700;
          }
  
          ul {
            p, li {
              font-size: 14px;
              line-height: 16px;
            }
  
            li {
              font-size: 13px;
            }
          }
  
          .buttons {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            column-gap: unset;
            row-gap: 16px;
  
            .button {
              width: 100%;
            }
          }
        }
      }    
    }
  }
}
