@import "../../../assets/scss/utils/variables";

/* stylelint-disable */

.Toastify__toast-container {
  z-index: 99999999 !important;

  .Toastify__toast-theme--light {
    border-radius: 6px !important;
  
    .Toastify__toast-body {
      color: $color-primary;
      font-weight: 500;
      line-height: 22px;
      font-size: 16px;
      font-family: SpaceGroteskRegular, sans-serif;
    }
  
    .Toastify__close-button {
      opacity: 1;
      align-self: unset;
  
      svg {
        width: 20px;
        height: 20px;
  
        path {
          fill: $color-primary;
        }
      }
    }
  
    &.Toastify__toast--success {
      background-color: $color-light-green;
  
      .Toastify__progress-bar--success {
        background-color: $color-green;
      }
  
      .Toastify__toast-icon {
        svg {
          fill: $color-green;
        }
      }
    }
  
    &.Toastify__toast--error {
      background-color: $color-light-red;
  
      .Toastify__progress-bar--success {
        background-color: $color-red;
      }
  
      .Toastify__toast-icon {
        svg {
          fill: $color-red;
        }
      }
    }
  }
}

/* stylelint-enable */
