$color-white: #fff;
$color-primary: #12263e;
$color-box: #2f4157;
$color-muted: #3c4f65;
$color-secondary: #192d45;
$color-grey: #eaeff5;
$color-bg-grey: #f5f6fa;
$color-bg-blue: #ebf5ff;
$color-blue: #2b63e3;
$color-blue-hover: #174fcf;
$color-dark-blue: #0941c1;
$color-light-blue: #4d88d4;
$color-oceanic-blue: #5d8fff;
$color-linear: linear-gradient(180deg, #1c3048 4.1%, #12263e 73.47%);
$color-green: #00c9bd;
$color-green-hover: #09d0c3;
$color-light-green: #d6f6f4;
$color-red: #fd4c5c;
$color-light-red: #ffe2e5;
$color-orange: #f69f29;
$color-light-orange: #fef0dd;
$color-light: #e5e5e5;
$color-midnight-blue: #0F2239;
$color-light-steel-gray: #94A3B8;
$color-slate-gray: #64748B;
$color-light-silver: #D8DCE0;
$color-icy-gray: #e2e8f0;
$color-navy-slate: #64728b;
$color-gentle-blue: #98A2B3;
$breakpoint-xs: 380px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-md-large: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1400px;
$breakpoint-xxl: 1920px;
