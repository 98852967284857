@import "../../../assets/scss/utils/variables";

.button {
  padding: 14px 32px;
  border-radius: 8px;
  text-align: center;
  color: $color-white;
  background-color: $color-blue;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  font-family: SpaceGroteskMedium, sans-serif;
  cursor: pointer;
  transition: 0.2s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    opacity: 0.9;
  }

  .loader {
    border: 2px solid $color-white;
    border-radius: 50%;
    border-top: 2px solid $color-blue;
    width: 15px;
    height: 15px;
    animation: spin 1s linear infinite;

    &.pink {
      border-top: 2px solid $color-red;
    }
  }

  &.light-button {
    background-color: $color-white;
    color: $color-primary;
    border: 1px solid $color-light-silver;
  
    .loader {
      border: 2px solid $color-primary;
      border-top: 2px solid $color-white;
    }
  }
  
  &.dark {
    background-color: $color-oceanic-blue;
  
    .loader {
      border-top: 2px solid $color-oceanic-blue;
    }
  
    &.light-button {
      background-color: $color-box;
      color: $color-white;
      border: 1px solid $color-slate-gray;
    
      .loader {
        border: 2px solid $color-box;
        border-top: 2px solid $color-white;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
