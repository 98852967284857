@import 'assets/scss/utils/variables';
@import 'assets/scss/reset/reset';

.container {
  .top-content {
    position: relative;
    background-color: $color-primary;
    height: 320px;
    overflow: hidden;
  
    .bg {
      background: url("./../../../assets/images/header-bg.svg");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0.5;
    }
  
    header {
      max-width: 920px;
      width: 100%;
      margin: 0 auto;
      background-repeat: no-repeat;
      position: relative;
  
      .header {
        width: 100%;
        padding-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .logo {
          display: flex;
          align-items: center;
          column-gap: 12px;

          .logo-img {
            max-width: 150px;
          }

          span {
            font-size: 22px;
            font-weight: 500;
            line-height: 25px;
            color: $color-white;
            font-family: SpaceGroteskBold, sans-serif;
          }
        }
  
        nav {
          ul {
            display: flex;
            align-items: center;
            column-gap: 16px;
  
            li {
              a {
                color: $color-white;
                font-family: SpaceGroteskMedium, sans-serif;
                font-size: 17px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                padding: 8px;
                border-radius: 4px;
                transition: 400ms all;
              }
  
              &.active {
                a {
                  color: $color-green;
                  background-color: $color-secondary;
                }
              }
  
              &:hover {
                a {
                  color: rgba($color: $color-green, $alpha: 80%);
                  background-color: $color-secondary;
                }
              }
  
              &.actions {
                display: flex;
                flex-direction: row-reverse;
              }
            }
          }
        }
  
        .mobile-menu {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xl) {
  .container {
    .top-content {
      height: 270px;
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .container {
    .top-content {
      height: 240px;
      
      header {
        padding: 0 16px;

        .header {
          .logo {
            .logo-img {
              max-height: 60px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .container {
    .top-content {
      height: 180px;
  
      header {
        .header {
          nav {
            display: none;
          }
  
          .mobile-menu {
            display: block;
  
            .menu-toggler {
              display: flex;
              flex-direction: column;
              row-gap: 3px;
              justify-content: center;
              cursor: pointer;
  
              span {
                width: 22px;
                height: 3px;
                border-radius: 1.5px;
                background-color: $color-white;
  
              }
            }
  
            .menu-content {
              position: fixed;
              top: 0;
              left: 0;
              width: 100vw;
              height: 100%;
              background: rgb(67 80 112 / 70%);
              backdrop-filter: blur(4px);
              z-index: 9999;
              visibility: hidden;
              opacity: 0;
              transition: 400ms all;
              overflow: hidden;
  
              .menu {
                width: 284px;
                height: 100%;
                background-color: $color-white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 32px 20px 20px;
                border-radius: 8px 0 0 8px;
                position: absolute;
                top: 0;
                right: -100%;
                transition: 400ms all;
                background-image: url('../../../assets/images/mobile-menu-bg.png');
                overflow: hidden;
  
                .circle {
                  background-color: $color-light-green;
                  border-radius: 50%;
                  position: absolute;
                  width: 88px;
                  height: 88px;
                  top: 120px;
                  right: -44px;
                  opacity: 0.5;
                  z-index: 1;
  
                  &:nth-child(2) {
                    width: 120px;
                    height: 120px;
                    top: unset;
                    bottom: 36px;
                    right: unset;
                    left: -40px;
                  }
  
                  &:nth-child(3) {
                    width: 32px;
                    height: 32px;
                    top: unset;
                    bottom: 156px;
                    right: 32px;
                  }
                }
  
                .top {
                  z-index: 2;
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 88px;
                }
  
                .mobile-navbar {
                  z-index: 2;
                  display: block;
                  flex: 1;
                  width: 100%;
                  
                  ul {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    column-gap: unset;
                    row-gap: 12px;
  
                    li {
                      width: 100%;
  
                      a {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        column-gap: 8px;
                        justify-content: flex-start;
                        color: $color-primary;
                        font-family: SpaceGroteskMedium, sans-serif;
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 22px;
                        padding: 8px;
                        border-radius: 4px;
                        transition: 400ms all;
                      }
  
                      &.active,
                      &:hover {
                        a {
                          color: $color-primary;
                          background-color: $color-light-green;
                        }
                      }
                    }
                  }
                }
  
                .subscribe-container {
                  z-index: 2;
                }
              }
            }
  
            &.active {
              .menu-content {
                visibility: visible;
                opacity: 1;
  
                .menu {
                  right: 0;
                }
              }
            }
          }
        }
      }
    }

    &.dark {
      .top-content {
        header {
          .header {
            .mobile-menu {
              .menu-content {
                .menu {
                  background-color: $color-primary;
    
                  .circle {
                    background-color: $color-muted;
                  }
    
                  .mobile-navbar {
                    ul {
                      li {
                        a {
                          color: $color-white;
                        }
    
                        &.active,
                        &:hover {
                          a {
                            color: $color-white;
                            background-color: $color-muted;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
