@import 'assets/scss/utils/variables';
@import 'assets/scss/reset/reset';

.container {
  footer {
    margin-top: 120px;
    height: 64px;
    background-color: $color-bg-grey;
  
    .footer {
      max-width: 920px;
      width: 100%;
      height: 100%;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;

      nav {
        ul {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 12px;

          li {
            a {
              font-size: 17px;
              line-height: 22px;
              font-weight: 500;
              color: $color-muted;
              font-family: SpaceGroteskMedium, sans-serif;
              padding: 8px;
              border-radius: 4px;
              transition: 400ms all;

              &:hover {
                background-color: $color-icy-gray;
                color: $color-primary;
              }
            }
          }
        }
      }
  
      .powered-by {
        display: flex;
        align-items: center;
        gap: 12px;
  
        span {
          font-size: 17px;
          line-height: 22px;
          font-weight: 500;
          color: $color-primary;
          font-family: SpaceGroteskMedium, sans-serif;
        }
      }

      .page-name {
        display: flex;
        align-items: center;

        h1 {
          font-size: 17px;
          font-weight: 500;
          color: $color-primary;
          font-family: SpaceGroteskMedium, sans-serif;

          span {
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
            margin-right: 3px;
          }
        }
      }
    }
  }

  &.dark {
    footer {    
      background-color: $color-secondary;
  
      .footer {
        nav {
          ul {  
            li {
              a {
                color: $color-gentle-blue;
  
                &:hover {
                  background-color: unset;
                  color: $color-green
                }
              }
            }
          }
        }

        .powered-by {
          span {
            color: $color-white;
          }
        }

        .page-name {
          h1 {
            color: $color-gentle-blue;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .container {
    footer {
      padding: 0 16px;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .container {
    footer {
      margin-top: 90px;
      height: max-content;
      padding: 16px;

      .footer {
        nav {
          ul {
            flex-direction: column;
            column-gap: unset;
            row-gap: 12px;
            align-items: flex-start;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .container {
    footer {
      margin-top: 60px;
  
      .footer {
        .powered-by {
          flex-direction: column;
          gap: 6px;
  
          span {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
