@import 'assets/scss/utils/variables';

.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    main {
        flex: 1;
    }

    &.dark {
        background-color: $color-primary;
    }
}

.justify-content-center {
    justify-content: center !important;
}

.text-semi-bold {
    font-family: SpaceGroteskSemiBold, sans-serif;
    font-weight: 700;
}

.w-100 {
    width: 100%;
}
