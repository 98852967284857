@import "../../../assets/scss/utils/variables";

.form-element {
  display: flex;
  flex-direction: column;
  row-gap: 9px;
  position: relative;

  .input {
    display: flex;
    align-items: center;

    .simple-input {
      width: 100%;
      height: 48px;
      padding: 12px 16px;
      background-color: $color-white;
      color: $color-primary;
      border-radius: 8px;
      transition: all 400ms;
      border: 1px solid $color-light-silver;

      &.empty,
      &.has-value {
        font-size: 14px;
        font-family: SpaceGroteskRegular, sans-serif;
        line-height: 18px;
      }

      &.has-value {
        height: 48px;
        padding-bottom: 0;
      }

      &:hover {
        border: 1px solid $color-blue !important;
      }

      &:focus + .input-placeholder {
        top: 8px;
        left: 16px;
        font-size: 10px;
        line-height: 14px;
        font-family: SpaceGroteskRegular, sans-serif;
        padding-bottom: 9px;
        color: $color-muted;
        opacity: 1;
      }

      &.error {
        border-color: $color-red !important;
      }

      &:focus {
        border: 1px solid $color-blue !important;

        &.empty,
        &.has-value {
          height: 48px;
          padding-bottom: 0;
        }

        &.error {
          border-color: $color-red !important;
        }
      }

      &::placeholder {
        color: $color-muted;
        font-size: 14px;
        line-height: 18px;
        font-family: SpaceGroteskRegular, sans-serif;
      }
    }
  }

  .label {
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
    font-weight: 400;

    &.empty {
      left: 16px;
      top: 16px;
      font-size: 14px;
      line-height: 18px;
      opacity: 0.5;
      color: $color-primary;
      font-family: SpaceGroteskRegular, sans-serif;
    }

    &.has-value {
      top: 8px;
      left: 16px;
      font-size: 10px;
      font-family: SpaceGroteskRegular, sans-serif;
      line-height: 14px;
      color: $color-muted;
    }

    &.error {
      color: $color-red !important;
    }
  }

  .error {
    display: flex;
    align-items: center;
    column-gap: 8px;

    &-text {
      font-family: SpaceGroteskRegular, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: $color-red;
    }
  }

  &.dark {
    .input {
      .simple-input {
        background-color: $color-box;
        color: $color-white;
        border: 1px solid $color-slate-gray;
  
        &:focus + .input-placeholder {
          color: $color-slate-gray;
        }
  
        &:focus {
          border: 1px solid $color-oceanic-blue !important;
        }

        &:hover {
          border: 1px solid $color-oceanic-blue !important;
        }
  
        &::placeholder {
          color: $color-slate-gray;
        }
      }
    }
  
    .label {
      &.empty {
        color: $color-white;
      }
  
      &.has-value {
        color: $color-slate-gray;
      }
    }
  }
}
