@import "../../../assets/scss/utils/variables";

/* stylelint-disable */

.ReactModal {
  &__Overlay {
    z-index: 9999;
    width: 100% !important;
    position: fixed;
    inset: 0;
    background: rgba(67, 80, 112, 0.70);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }

  &__Content {
    background-color: $color-white !important;
    width: 390px;
    height: max-content;
    margin: auto;
    border: 1px solid $color-light-silver;
    box-shadow: 6.248px 8.748px 14.996px 0px rgba(108, 126, 147, 0.10);
    align-self: flex-start;
    padding: 0 !important;
    border-radius: 16px !important;
    transform: translateY(-100%) !important;
    transition: transform 500ms;

    &--after-open {
      transform: translateY(0) !important;
    }

    .modal-container {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding: 24px !important;

      .modal-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-family: SpaceGroteskSemiBold, sans-serif;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            color: $color-primary;
        }

        .close-icon {
            cursor: pointer;
        }
      }

      .modal-content {
        display: flex;
        flex-direction: column;
        row-gap: 24px;

          .description {
              font-family: SpaceGroteskRegular, sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              color: $color-primary;
          }

          .info-box {
            width: 100%;
            padding: 8px 12px;
            border-radius: 6px;
            margin-top: 12px;
            font-family: SpaceGroteskRegular, sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: $color-muted;
            border: 1px solid var(--light-grey-2, #E8EAEC);
            background-color: $color-bg-grey;
          }

          .buttons {
            display: flex;
            justify-content: space-between;
            column-gap: 12px;
            margin-top: 24px;

            .button {
              width: calc(50% - 6px);
            }
          }
      }
    }
  }
}

.dark {
  .ReactModal {
    &__Overlay {
      background-color: rgba(216, 220, 224, 0.5) !important;
    }

    &__Content {
      background-color: $color-primary !important;
      border: 1px solid $color-primary !important;
      box-shadow: 6.248px 8.748px 14.996px 0px rgba(108, 126, 147, 0.10);

      .modal-header {
          .title {
              color: $color-white;
          }
      }

      .modal-content {
          .description {
              color: $color-white;
          }

          .info-box {
            color: $color-white;
            border: none;
            background-color: transparent;
          }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .ReactModal {
    &__Content {
      left: 0 !important;
      right: 0 !important;
      width: calc(100vw - 32px);
    }
  }
}

/* stylelint-enable */
