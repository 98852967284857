@import "../../../assets/scss/utils/variables";

.fullscreen-loader {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-white;

  .spinner {
    animation: rotating 2s linear infinite;
  }
}

.content-loader {
  width: 100%;
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    animation: rotating 2s linear infinite;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
