@font-face {
  font-family: SpaceGroteskRegular;
  src: url("../../../assets/fonts/SpaceGrotesk-Regular.ttf");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: SpaceGroteskMedium;
  src: url("../../../assets/fonts/SpaceGrotesk-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: SpaceGroteskSemiBold;
  src: url("../../../assets/fonts/SpaceGrotesk-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: SpaceGroteskBold;
  src: url("../../../assets/fonts/SpaceGrotesk-Bold.ttf");
  font-weight: bold;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
}
