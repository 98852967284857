@import './../../assets/scss/utils/variables';
@import './../../assets/scss/reset/reset';

.container {
  main.not-found {
    width: 920px;
    margin: 0 auto;
    flex: 1;
    
    .not-found-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
      padding: 40px;
      border: 1px solid $color-light-silver;
      background-color: $color-white;
      border-radius: 16px;
      position: relative;
      top: -140px;
  
      .not-found-text {
        font-size: 28px;
        line-height: 36px;
        font-weight: 600;
        color: $color-primary;
        text-align: center;
        padding: 16px;
        font-family: SpaceGroteskBold, sans-serif;
      }
    }
  }

  &.dark {
    main.not-found {
      .not-found-container {
        border: 1.5px solid $color-slate-gray;
        background-color: $color-secondary;
    
        .not-found-text {
          color: $color-white;
          font-family: SpaceGroteskBold, sans-serif;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .container {
    main.not-found {
      width: 90%;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .container {
    main.not-found {
      .not-found-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;
        padding: 40px;
        border: 1px solid $color-light-silver;
        background-color: $color-white;
        border-radius: 8px;
        position: relative;
        top: -50px;
    
        .not-found-text {
          font-size: 28px;
          line-height: 36px;
          font-weight: 600;
          color: $color-primary;
          text-align: center;
          padding: 16px;
        }
      }
    }
  }
}
