@import "../../../assets/scss/utils/variables";

.pulse {
  position: absolute;
  display: inline-block;
  width: 64px;
  height: 64px;
  border-radius: 50%;

  &::after {
    content: "";
    width: 88px;
    height: 88px;
    border-radius: 50%;
    right: -12px;
    top: -12px;
    position: absolute;
    animation: pulse 1.5s infinite;
  }

  &.error {
    background: rgba($color: $color-red, $alpha: 60%);

    &::after {
      background: rgba($color: $color-red, $alpha: 60%);
    }
  }

  &.success {
    background: rgba($color: $color-green, $alpha: 60%);

    &::after {
      background: rgba($color: $color-green, $alpha: 60%);
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .pulse {
    width: 48px;
    height: 48px;
  
    &::after {
      width: 64px;
      height: 64px;
      right: -8px;
      top: -8px;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.6);
    opacity: #ddd;
  }

  100% {
    transform: scale(1);
    opacity: #ddd;
  }
}
